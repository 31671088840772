import anime from 'animejs';
const throttle = require('lodash.throttle');

export function carousel(selector = '.js-carousel-container') {
  const carouselContainer = document.querySelectorAll(selector);
  if(carouselContainer){
    for(let i = 0; i < carouselContainer.length; i++) {
      const target = carouselContainer[i];
      const carousel = target.querySelector('.js-carousel');
      const cell = carousel.querySelectorAll('.js-cell');
      const cellPlaceholderContent = target.querySelector('.js-cell-placeholder-content');
      const previous = target.querySelector('.js-nav-previous');
      const next = target.querySelector('.js-nav-next');
      const currentIndex = target.querySelector('.js-current-index');
      const total = cell.length-1;
      const lastCell = cell[total];
      let PosArr = '';
      let current = 0;
      let flg = true;

      const SET_POS = function () {
        if (target.offsetWidth >= lastCell.clientWidth) {
          const placeholderWidth = target.offsetWidth - lastCell.clientWidth;
          cellPlaceholderContent.style.width = `${placeholderWidth}px`;
        } else {
          cellPlaceholderContent.style.width = 0;
        }
        PosArr = new Array;
        for(let i = 0; i <= total; i++) {
          const target = cell[i];
          const targetScrollLeft = target.offsetLeft;
          const targetScrollRight = targetScrollLeft + target.offsetWidth;
          PosArr[i] = [targetScrollLeft, targetScrollRight];
        }
      }

      const CAROUSEL_POS_CHECK = function () {
        const scLeft = carousel.scrollLeft;
        for(let i = 0; i <= PosArr.length - 1; i++) {
          const thisScrollLeft = PosArr[i][0];
          const thisScrollRight = PosArr[i][1];
          if (flg && (thisScrollLeft - 1 <= scLeft && thisScrollRight >= scLeft)) {
            current = i
            currentIndex.innerHTML = current+1;
          }
        }
      }

      SET_POS();
      CAROUSEL_POS_CHECK();

      window.addEventListener('resize', throttle(function(){
        SET_POS();
        CAROUSEL_POS_CHECK();
      }, 600), false);

      carousel.addEventListener('scroll', throttle(CAROUSEL_POS_CHECK, 600), false);

      let anim = anime();
      const CAROUSEL_SCROLLER = (pos) => {
        anim.pause();
        flg = false;
        anim = anime({ 
          targets: carousel,
          scrollLeft: pos,
          duration: 900,
          easing: 'easeInOutQuart'
        });
        anim.complete = () => {
          flg = true;
        }
      }

      previous.addEventListener('click', function (e) {
        e.preventDefault();
        if (current == 0) {
          current = total;
        } else {
          current--;
        }
        currentIndex.innerHTML = current+1;
        const target = PosArr[current][0];
        CAROUSEL_SCROLLER(target);
        return false;
      });

      next.addEventListener('click', function (e) {
        e.preventDefault();
        if (current == total) {
          current = 0;
        } else {
          current++;
        }
        currentIndex.innerHTML = current+1;
        const target = PosArr[current][0];
        CAROUSEL_SCROLLER(target);
        return false;
      });
    }
  }
}