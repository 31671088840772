// Stylesheets
import './assets/scss/site.scss';

import {fixSvgIntrinsicSizing} from './assets/modules/fixSvgIntrinsicSizing';
import {hover} from './assets/modules/hover';
import {nav} from './assets/modules/nav';
import {langnav} from './assets/modules/langnav';
import {modal} from './assets/modules/modal';
import {lazyload} from './assets/modules/lazyload';
import {carousel} from './assets/modules/carousel';
import {initPhotoSwipeFromDOM} from './assets/modules/photoswipe-init';
import {Pjax} from 'pjax-api';

const init = () => {
  fixSvgIntrinsicSizing();
  hover();
  langnav();
  modal();
  lazyload();
  carousel();
  initPhotoSwipeFromDOM('.js-pswp-container');
}

nav();
init();

new Pjax({
  areas: [
    '#main, #site-nav, #header-nav',
    'body'
  ],
  update: {
    css: false,
    script: false
  }
});
 
document.addEventListener('pjax:ready', function(e) {
  document.body.classList.remove('is-nav-active');
  init();
  setTimeout(() => {
    if(document.getElementById('main').classList.contains('js-dark-trigger')) {
      document.body.classList.add('is-dark');
    } else {
      document.body.classList.remove('is-dark');
    }
  }, 0);
  //gtag('config', ga_code, {'page_path' : location.pathname});
});