export function modal() {
  const modalTrigger = document.querySelectorAll('[data-modal]');
  const modal = document.querySelectorAll('.js-modal');
  const modalClose = document.querySelectorAll('.js-modal-close');

  const close = function(e) {
    e.preventDefault();
    for(let i = 0; i < modalTrigger.length; i++) {
      const target = modalTrigger[i];
      target.classList.remove('is-modal-current');
    }
    for(let i = 0; i < modal.length; i++) {
      const target = modal[i];
      target.classList.remove('is-active');
    }
    document.body.classList.remove('is-modal-active');
  };

  for(let i = 0; i < modalTrigger.length; i++) {
    const target = modalTrigger[i];
    target.addEventListener('click', function(e) {
      e.preventDefault();
      const data = target.dataset.modal;
      const targetModal = document.querySelector(data);
      for(let i = 0; i < modalTrigger.length; i++) {
        const target = modalTrigger[i];
        target.classList.remove('is-modal-current');
      }
      for(let i = 0; i < modal.length; i++) {
        const target = modal[i];
        target.classList.remove('is-active');
      }
      target.classList.add('is-modal-current');
      targetModal.classList.add('is-active');
      return false;
    });
  }

  for(let i = 0; i < modalClose.length; i++) {
    const target = modalClose[i];
    target.addEventListener('click', close)
  }

  document.addEventListener('keyup', function(e) {
    if(e.keyCode === 27) close(e); // escape key maps to keycode `27`
  });
}