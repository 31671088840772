export function langnav() {
  const triggerEn = document.getElementsByClassName('js-trigger-en');
  const triggerCn = document.getElementsByClassName('js-trigger-cn');
  const triggerJa = document.getElementsByClassName('js-trigger-ja');
  
  for(let i = 0; i < triggerEn.length; i++) {
    const target = triggerEn[i];
    target.addEventListener('click', function(e) {
      e.preventDefault();
      document.body.classList.remove('is-cn','is-ja');
      document.body.classList.add('is-en');
      for(let i = 0; i < triggerCn.length; i++) {
        const target = triggerCn[i];
        target.classList.remove('is-current');
      }
      for(let i = 0; i < triggerJa.length; i++) {
        const target = triggerJa[i];
        target.classList.remove('is-current');
      }
      for(let i = 0; i < triggerEn.length; i++) {
        const target = triggerEn[i];
        target.classList.add('is-current');
      }
      Cookies.set('songhelang', 'en');
      return false;
    });
  }

  for(let i = 0; i < triggerCn.length; i++) {
    const target = triggerCn[i];
    target.addEventListener('click', function(e) {
      e.preventDefault();
      document.body.classList.remove('is-en','is-ja');
      document.body.classList.add('is-cn');
      for(let i = 0; i < triggerEn.length; i++) {
        const target = triggerEn[i];
        target.classList.remove('is-current');
      }
      for(let i = 0; i < triggerJa.length; i++) {
        const target = triggerJa[i];
        target.classList.remove('is-current');
      }
      for(let i = 0; i < triggerCn.length; i++) {
        const target = triggerCn[i];
        target.classList.add('is-current');
      }
      Cookies.set('songhelang', 'cn');
      return false;
    });
  }
  
  for(let i = 0; i < triggerJa.length; i++) {
    const target = triggerJa[i];
    target.addEventListener('click', function(e) {
      e.preventDefault();
      document.body.classList.remove('is-en','is-cn');
      document.body.classList.add('is-ja');
      for(let i = 0; i < triggerEn.length; i++) {
        const target = triggerEn[i];
        target.classList.remove('is-current');
      }
      for(let i = 0; i < triggerCn.length; i++) {
        const target = triggerCn[i];
        target.classList.remove('is-current');
      }
      for(let i = 0; i < triggerJa.length; i++) {
        const target = triggerJa[i];
        target.classList.add('is-current');
      }
      Cookies.set('songhelang', 'ja');
      return false;
    });
  }
}